import { motion } from 'framer-motion'
import React from 'react'

export default function Main({ children }) {
  return (
    <motion.main
      role="main"
      className="relative overflow-hidden"
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 200 }}
      transition={{
        type: 'spring',
        mass: 0.35,
        stiffness: 75,
        duration: 0.3,
      }}
    >
      {children}
    </motion.main>
  )
}
